import style from'./Navigation.module.scss';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import * as ga from '../functions/GAEvents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navigation(props) {

  //Manager for the mobile menu state
  const [menuToggle, setMenuToggle] = useState("")
  const toggleMenu = () => {
    if (menuToggle === "") {
      setMenuToggle("active")
    } else {
      setMenuToggle("")
    }
  }

  //Manager for the mobile submenu state
  const [subMenuToggle, setSubMenuToggle] = useState("")
  const toggleSubMenu = () => {
    if (subMenuToggle === "") {
      setSubMenuToggle("active")
    } else {
      setSubMenuToggle("")
    }
  }

  //Manager for nav menu as a whole
  const togglePage = () => {
    setSubMenuToggle("")
    setMenuToggle("")
  }

  //Handles the navigation layout when the window is in the mobile or tablet state
  let mobileMenu = null
  let mobileArrow = null
  let webMenu = null

  if (props.windowSize !== "Web") {
    if (menuToggle === "active") {
      mobileMenu = (
        <div className={`${style.menuButton} ${style.active}`} onClick={() => toggleMenu()}>
          <FontAwesomeIcon icon={['fal', 'times']} />
        </div>
      )
  
      if (subMenuToggle === "active") {
        mobileArrow = (
          <div className={`${style.dropdownArrow} ${style.active}`}> 
            <FontAwesomeIcon icon={['fal', 'chevron-up']} />
          </div> 
        )
      } else {
        mobileArrow = (
          <div className={style.dropdownArrow} > 
            <FontAwesomeIcon icon={['fal', 'chevron-down']} />
          </div> 
        )
      }
      
    } else {
      mobileMenu = (
        <div className={style.menuButton} onClick={() => toggleMenu()}>
          <FontAwesomeIcon icon={['fal', 'bars']} />
        </div>
      )
    }
  } else {
    webMenu = <FontAwesomeIcon icon={['fal', 'caret-down']} />
  }

  let externalMenu = null
  externalMenu = (
    <div className={`${style.externalMenu}`}>
      {/* {props.windowSize === "Web" ? <a href="https://www.basler.com/Employment/" className={style.navSubLink} onClick={() => ga.event('Careers Page')}>Careers</a> : null} */}
      <a href="https://www.basler.com/" className={style.navSubLink} onClick={() => ga.event('Basler Page')}>Basler Electric</a>
      {/*<a href="https://www.basler.com/plastics" className={style.navSubLink}  onClick={() => ga.event('Plastics Page')}>Basler Plastics</a>*/}
    </div>
  )

  let dropdownMenu = null
  dropdownMenu = (
    <div className={`${style.dropdown} ${subMenuToggle === "active" ? style["active"] : ''}`}>
      <Link href={'/e2-engineering-services'}><a className={style.navSubLink} onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>E&#178; Engineering Services</a></Link>
      <Link href={'/segrity-products-and-services'}><a className={style.navSubLink} onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>SEGRITY Products & Services</a></Link>
    </div>
  )

  let imageType = "png"
  if (props.webp === "webp") {
    imageType = "webp"
  }

  //SEGRITY was founded with a vision of providing control systems and hydro governor solutions that the industry needs, not what suppliers think they should have. We believe in services with integrity.

  return (
    <div className={style.Navigation}>
      <Link href={'/'}>
        <a className={style.logoContainer}>
          <img className={style.logo} alt="Basler Services Logo" src={'/Assets/Images/BS_White_Logo_WS.' + imageType} />
          <div className={style.logoSubText}>A Wholly Owned Subsidiary of Basler Electric</div>
        </a>
      </Link>
      {mobileMenu}
      {props.windowSize === "Web" ? externalMenu : ""}
      <nav className={`${menuToggle === "active" ? style[menuToggle] : ''}`}>
        <ul>
          <li className={`${style.navLink}`}>
            <Link href={'/'}><a onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>Home</a></Link>
          </li>
          {props.windowSize !== "Web" ? externalMenu : ""}
          <li className={style.navLink}>
            <Link href={'/about-us'}><a onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>About Us</a></Link>
          </li>
          <li className={`${style.navLink} ${subMenuToggle === "active" ? style["active"] : ''}`}> 
            <a onClick={props.windowSize !== "Web" ? () => toggleSubMenu() : undefined}>Products & Services {webMenu}{mobileArrow}</a>
            {props.windowSize === "Web" ? dropdownMenu : ""}
          </li> 
          {props.windowSize !== "Web" ? dropdownMenu : ""}
          <li className={style.navLink}>
            <Link href={'/case-studies'}><a onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>Case Studies</a></Link>
          </li>
          <li className={style.navLink}>
            <Link href={'/contact-us'}><a onClick={props.windowSize !== "Web" ? () => togglePage() : undefined}>Contact Us</a></Link>
          </li>
        </ul>
      </nav>     
    </div>
  );
}

export default Navigation;
